


































































































import CreateProfileTs from './CreateProfileTs';
export default class CreateProfile extends CreateProfileTs {}
